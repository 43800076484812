import { ListItem, ListItemButton } from '@mui/material'
import { trabaApi } from '@traba/api-utils'
import { useAlert } from '@traba/context'
import {
  TabPanel,
  Tab,
  Tabs,
  TabsContainer,
  SvgIcon,
} from '@traba/react-components'
import { Text } from '@traba/react-components'
import { InfoTooltip } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  ActionableRequirement,
  ActionType,
  AdjudicationStatus,
  BackgroundCheckAssessment,
  BackgroundCheckStatus,
  EmploymentType,
  PhoneNumberStatus,
  W9FormStatus,
  WorkerJobPlacementStatus,
  WorkerShiftForOps as WorkerShift,
} from '@traba/types'
import { AccountStatus, InstantPayEligibilityStatus } from '@traba/types'
import { PaidBackup } from '@traba/types'
import { WorkerMetric } from '@traba/types'
import { WorkerProfile, WorkerProfileStatus } from '@traba/types'
import { WorkerTier } from '@traba/types'
import { Transportation, Worker } from '@traba/types'
import copyToClipboard from 'copy-to-clipboard'
import { startOfDay, addYears, subDays } from 'date-fns'
import { useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Badge, Button, Col, Icon, IconButton, Row } from 'src/components/base'
import Avatar from 'src/components/base/Avatar'
import AccountStatusBadge from 'src/components/base/Badge/AccountStatusBadge'
import { EmploymentTypeBadge } from 'src/components/base/Badge/EmploymentTypeBadge'
import GenderBadge from 'src/components/base/Badge/GenderBadge'
import StripeAccountPayoutsBadge from 'src/components/base/Badge/StripeAccountPayoutsBadge'
import { ButtonVariant } from 'src/components/base/Button/types'
import { CircularProgress } from 'src/components/base/CircularProgress/CircularProgress'
import ConfirmationDialog from 'src/components/ConfirmationDialog/ConfirmationDialog'
import { MainLayout } from 'src/components/layout/MainLayout/MainLayout'
import { PhoneNumberWithStatus } from 'src/components/PhoneNumberWithStatus'
import { WorkerNoteDrawer } from 'src/components/WorkerNoteDrawer'
import { WorkerRating } from 'src/components/WorkerRating/WorkerRating'
import { UserRolePermission } from 'src/context/user/types'
import { useAccountStatus } from 'src/hooks/useAccountStatus'
import { useApi } from 'src/hooks/useApi'
import { useHotSettings } from 'src/hooks/useHotSettings'
import { usePermissions } from 'src/hooks/usePermissions'
import useTimezonedDates from 'src/hooks/useTimezonedDates'
import { useOldEarnings, useTotalEarnings } from 'src/hooks/useTotalEarnings'
import { useWorkerProfile } from 'src/hooks/useWorkerProfile'
import { useWorkerRequirementActions } from 'src/hooks/useWorkerRequirementActions'
import { useCreateOrUpdateWorkerRequirementAction } from 'src/hooks/useWorkerRequirementActions'
import { useWorkerTrainings } from 'src/hooks/useWorkerTrainings'
import { useWorkerTrainingShifts } from 'src/hooks/useWorkerTrainingShifts'
import { useWorkerUploadedResumes } from 'src/hooks/useWorkerUploadedResumes'
import { ZEAL_PORTAL_BASE_URL } from 'src/hooks/useZeal'
import { getStarRatingFromWorkerProfileAttributes } from 'src/utils/attributeUtils'
import { openInNewTab } from 'src/utils/helperUtils'
import { getLanguageNames } from 'src/utils/languageUtils'
import { getMoneyString, toPercentString } from 'src/utils/stringUtils'
import WorkerSearchDropdown from '../WorkerSearchScreen/components/WorkerSearchDropdownLegacy'
import BackgroundCheckStatusBadge from './components/BackgroundCheckStatusBadge'
import { InvitedShiftsTab } from './components/InvitedShiftsTab'
import { MessagesTab } from './components/MessagesTab'
import { PaidBackupsTab } from './components/PaidBackupsTab'
import { PaymentHistoryTab } from './components/PaymentHistoryTab'
import PhoneNumberStatusBadge from './components/PhoneNumberStatusBadge'
import { QualificationsTab } from './components/QualificationsTab'
import W9FormStatusBadge from './components/W9FormStatusBadge'
import { WaitlistsTab } from './components/WaitlistsTab'
import { WardenExemptionsTab } from './components/WardenExemptionsTab'
import WorkerAccountManagement from './components/WorkerAccountManagement/WorkerAccountManagement'
import WorkerAttributes from './components/WorkerAttributes'
import WorkerCertifications from './components/WorkerCertifications'
import WorkerConnections from './components/WorkerConnections'
import WorkerDrugScreeningOrders from './components/WorkerDrugScreeningOrders'
import WorkerDrugScreeningResults from './components/WorkerDrugScreeningResults'
import { WorkerEarningsTab } from './components/WorkerEarningsTab'
import WorkerEligibilityConnections from './components/WorkerEligibilityConnections'
import { WorkerIncentivesTab } from './components/WorkerIncentivesTab'
import { WorkerJobPlacements } from './components/WorkerJobPlacements'
import { WorkerMessagesTab } from './components/WorkerMessagesTab'
import { WorkerShiftsTab } from './components/WorkerShiftsTab'
import * as S from './WorkerDetailsScreen.styles'

enum TabIndex {
  SHIFTS,
  PAID_BACKUPS,
  WAITLISTS,
  INVITED_SHIFTS,
  EARNINGS,
  PAYMENTS_HISTORY,
  BIZ_MESSAGES,
  DRUG_SCREENING,
  WORKER_MESSAGES,
  INCENTIVES,
  QUALIFICATIONS,
  ATTRIBUTES_CERTIFICATIONS,
  CONNECTIONS,
  ELIGIBILITY_CONNECTIONS,
  HIRED,
  RESUMES,
  WARDEN_EXEMPTIONS,
}

export function isWorkerEligibleForInstantPay(
  workerAccountStatus: AccountStatus | undefined,
): boolean {
  let workerEligibleForInstantPay = false
  if (workerAccountStatus?.payment?.instantPayEligibility) {
    workerEligibleForInstantPay = [
      InstantPayEligibilityStatus.ELIGIBLE,
      InstantPayEligibilityStatus.ELIGIBLE_WITH_WARNING,
    ].includes(workerAccountStatus.payment?.instantPayEligibility?.status)
  }
  return workerEligibleForInstantPay
}

export default function WorkerDetailsScreen() {
  const { handleError } = useAlert()
  const begOfToday = startOfDay(new Date())
  const nextYear = addYears(begOfToday, 1)
  const twoWeeksAgo = subDays(begOfToday, 14)
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    twoWeeksAgo,
    nextYear,
  ])
  const { workerId } = useParams()
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState<number>(0)
  const { hotSettings } = useHotSettings()

  const [manageWorker, setManageWorker] = useState<boolean>(false)
  const lastChatRef = useRef<null | HTMLDivElement>(null)
  const editWorkerAccess = usePermissions([UserRolePermission.EditWorker])
  const tz = useTimezonedDates()
  const { trainingSummary } = useWorkerTrainingShifts(workerId)
  const { completedTrainings } = useWorkerTrainings(workerId || '')
  const {
    resumes,
    isLoading: resumesAreLoading,
    deleteResume,
  } = useWorkerUploadedResumes(workerId || '')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [resumeIdToDelete, setResumeIdToDelete] = useState<string | undefined>()
  const [showDeleteResumeModal, setShowDeleteResumeModal] = useState(false)

  const {
    data: worker,
    isLoading: isLoadingWorker,
    isFetching: isFetchingWorker,
    refetch: refetchWorker,
  } = useApi<Worker & AccountStatus>(`/workers/${workerId}/worker-and-profile`)
  const { data: workerLanguages, isLoading: isLoadingWorkerLanguages } = useApi<
    WorkerProfile[]
  >(`workers/${workerId}/profile?category=LANGUAGES`)
  const params = new URLSearchParams()
  params.append('includeWorker', 'true')
  params.append('includeAccountStatus', 'true')
  params.append('includeEarningsSummary', 'true')
  params.append('minStartTime', dateRange[0]?.toISOString() ?? '')
  params.append('maxStartTime', dateRange[1]?.toISOString() ?? '')

  const { data: workerShifts, isLoading: isLoadingWorkerShifts } = useApi<
    WorkerShift[]
  >(`/workers/${workerId}/worker-shifts?${params.toString()}`)

  const {
    workerProfileAttributes,
    isLoading: isLoadingWorkerProfileAttributes,
    isFetching: isFetchingWorkerProfileAttributes,
  } = useWorkerProfile(workerId || '')

  const {
    hasActivePhoneNumberAction,
    hasActiveBackgroundCheckAction,
    hasActiveW9FormAction,
    refetchWorkerRequirementActions,
    isRefetchingWorkerRequirementActions,
  } = useWorkerRequirementActions(workerId || '')

  const [isLoadingW9FormUpdate, setIsLoadingW9FormUpdate] = useState(false)

  const { updatePhoneNumberStatus } = useAccountStatus(workerId || '')

  const createOrUpdateWorkerRequirementAction =
    useCreateOrUpdateWorkerRequirementAction()

  const handleBackgroundCheckAction = async (
    hasActiveBackgroundCheckAction: boolean,
  ) => {
    const workerRequirementActionUpdates = {
      workerId: workerId || '',
      requirementType: ActionableRequirement.BACKGROUND_CHECK,
      actionType: ActionType.BLOCKED_ALL,
    }

    if (!hasActiveBackgroundCheckAction) {
      await createOrUpdateWorkerRequirementAction.mutateAsync(
        workerRequirementActionUpdates,
      )
    } else {
      await createOrUpdateWorkerRequirementAction.mutateAsync({
        ...workerRequirementActionUpdates,
        expiryTime: new Date(),
      })
    }
    await refetchWorkerRequirementActions()
  }

  const handlePhoneNumberStatusUpdate = async ({
    status: updatedStatus,
    shouldSetAction = false,
    shouldLiftAction = false,
  }: {
    status: PhoneNumberStatus
    shouldSetAction?: boolean
    shouldLiftAction?: boolean
  }) => {
    const workerRequirementActionUpdates = {
      workerId: workerId || '',
      requirementType: ActionableRequirement.PHONE_NUMBER,
      actionType: ActionType.BLOCKED_ALL,
    }

    if (shouldSetAction) {
      await createOrUpdateWorkerRequirementAction.mutateAsync(
        workerRequirementActionUpdates,
      )
    }
    if (shouldLiftAction) {
      await createOrUpdateWorkerRequirementAction.mutateAsync({
        ...workerRequirementActionUpdates,
        expiryTime: new Date(),
      })
    }
    updatePhoneNumberStatus(updatedStatus, {
      onSuccess: async () => {
        await Promise.all([refetchWorker(), refetchWorkerRequirementActions()])
      },
    })
  }

  const handleW9FormActionUpdate = async (
    hasActiveAction: boolean,
    w9FormStatus?: W9FormStatus,
  ) => {
    const workerRequirementActionUpdates = {
      workerId: workerId || '',
      requirementType: ActionableRequirement.W9_FORM,
      actionType: ActionType.BLOCKED_ALL,
    }
    if (!hasActiveAction) {
      // If W9 form is not already requested, set status to REQUESTED, which also sets an active action.
      if (!w9FormStatus) {
        setIsLoadingW9FormUpdate(true)
        try {
          await trabaApi.post(`stripe/w9-form/request/${workerId}`)
        } catch (error) {
          const message = `There was an error setting the request for the worker's W-9 form.`
          handleError(
            error,
            'WorkerDetailsScreen -> stripe/w9-form/request',
            message,
          )
        } finally {
          setIsLoadingW9FormUpdate(false)
        }
      }
      // W9 has already been REQUESTED, only set the action.
      else {
        await createOrUpdateWorkerRequirementAction.mutateAsync({
          ...workerRequirementActionUpdates,
        })
      }
    } else {
      // Lift the action, regardless of the status.
      await createOrUpdateWorkerRequirementAction.mutateAsync({
        ...workerRequirementActionUpdates,
        expiryTime: new Date(),
      })
    }
    await Promise.all([refetchWorker(), refetchWorkerRequirementActions()])
  }

  const {
    data: paidBackups,
    isLoading: isLoadingPaidBackups,
    refetch: refetchPaidBackups,
  } = useApi<PaidBackup[]>(`/paid-backup/workers/${workerId}`)

  const { data: metrics, isLoading: isLoadingWorkerMetrics } =
    useApi<WorkerMetric>(`/workers/${workerId}/all-time-metrics`)

  const { data: workerTier, isLoading: isLoadingWorkerTier } = useApi<{
    tier: WorkerTier
  }>(`/workers/${workerId}/tier`)

  const { totalEarnings } = useTotalEarnings(workerId || '')
  const { oldTotalEarnings } = useOldEarnings(workerId || '')
  const [notesOpen, setNotesOpen] = useState<boolean>(false)
  const lifetimeEarnings =
    (totalEarnings?.totalEarned ?? 0) + (oldTotalEarnings?.amount ?? 0)

  if (
    isLoadingWorker ||
    isLoadingWorkerMetrics ||
    isLoadingWorkerLanguages ||
    isLoadingWorkerTier
  ) {
    return <div>Loading...</div>
  }
  if (!worker) {
    return <div>There was an error fetching the worker data</div>
  }
  const isW2Worker = worker.activeEmploymentTypes?.includes(EmploymentType.W2)
  const averagePayPerShift = hotSettings?.newEarningsTotalsEnabled
    ? metrics && metrics?.completedShifts > 0
      ? lifetimeEarnings / metrics?.completedShifts
      : 0
    : !!metrics?.netPayTotals?.usd && metrics?.completedShifts > 0
      ? metrics?.netPayTotals?.usd / metrics?.completedShifts
      : 0
  const stripePaidOut =
    ((totalEarnings?.totalStripePaidOut ?? 0) +
      (oldTotalEarnings?.amount ?? 0)) /
    100
  const stripeBalance = (totalEarnings?.totalStripeBalance ?? 0) / 100
  const stripePending = (totalEarnings?.totalStripePending ?? 0) / 100
  const trabaPendingAmount = (totalEarnings?.totalTrabaPending ?? 0) / 100

  const workerEligibleForInstantPay = isWorkerEligibleForInstantPay(worker)

  const getTransportationText = (
    transportation?: string,
  ): string | undefined => {
    switch (transportation) {
      case Transportation.DRIVE:
        return 'Drive'
      case Transportation.PUBLIC:
        return 'Public transportation'
      case Transportation.SHARE:
        return 'Ridesharing app'
      case Transportation.CARPOOL:
        return 'Carpool'
      default:
        return
    }
  }

  return (
    <>
      <MainLayout
        title={`${worker.firstName} ${worker.lastName} - Worker Details`}
      >
        <WorkerAccountManagement
          workerName={`${worker.firstName} ${worker.lastName}`}
          workerId={worker.uid}
          worker={worker}
          isOpen={manageWorker}
          setIsOpen={setManageWorker}
        />
        <S.Nav>
          <Text variant="body2" onClick={() => navigate('/workers/')}>
            <Icon name="leftArrow" />
            Worker Search
          </Text>
        </S.Nav>
        <S.Cover />
        <Row mt={-theme.space.xxs} alignCenter>
          <Row alignCenter>
            <Avatar src={worker.photoUrl} name={worker.firstName} />
            <Col ml={theme.space.sm} mt={theme.space.sm}>
              <Row mb={theme.space.xxs} alignCenter>
                <Text variant="h4" mr={theme.space.xxs}>
                  {worker.firstName} {worker.lastName}
                </Text>
                <AccountStatusBadge
                  accountStatus={worker.accountStatus}
                  approvalStatusReason={worker.approvalStatusReason}
                  accountSuspensionExpiration={worker.suspensionExpiration}
                />
                {!!worker.activeEmploymentTypes && (
                  <EmploymentTypeBadge
                    employmentTypes={worker.activeEmploymentTypes}
                  />
                )}
              </Row>
              <Text mr={theme.space.xxs} variant="body2">
                Joined on: {tz.getDateTime(worker.createdAt)}
              </Text>
            </Col>
          </Row>
          <Col>
            <Row justifyEnd>
              <S.DropdownWrapper>
                <WorkerSearchDropdown
                  showProfileLink={false}
                  worker={worker}
                  buttonVariant={ButtonVariant.OUTLINED}
                />
              </S.DropdownWrapper>
              <Button
                slim
                variant={ButtonVariant.OUTLINED}
                style={{ marginRight: theme.space.xxs }}
                leftIcon={<Icon name="edit" />}
                onClick={() => setManageWorker(true)}
                disabled={!editWorkerAccess}
              >
                Manage Worker
              </Button>

              <Button
                slim
                variant={ButtonVariant.FILLED}
                onClick={() => copyToClipboard(worker?.uid ?? '')}
                leftIcon={
                  <Icon name="copy" type="svg" size={14} color={'#fff'} />
                }
              >
                Copy UID
              </Button>
              <Link to={`/workers/${workerId}/logs`}>
                <Button
                  slim
                  variant={ButtonVariant.OUTLINED}
                  style={{ marginLeft: theme.space.xxs }}
                >
                  Audit Log
                </Button>
              </Link>
            </Row>
          </Col>
        </Row>
        <Row mt={24}>
          <Col>
            {workerId && hotSettings?.allowStoringRequiredAttributeLevel && (
              <WorkerRating
                workerId={workerId}
                opsStarLevel={getStarRatingFromWorkerProfileAttributes(
                  workerProfileAttributes,
                )}
                showBizRating={false}
                workerName={`${worker.firstName} ${worker.lastName}`}
                isLoading={
                  isLoadingWorkerProfileAttributes ||
                  isFetchingWorkerProfileAttributes
                }
              />
            )}
            <Text variant="caption" mb={theme.space.xxs}>
              EMAIL ADDRESS
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {worker.email}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              PHONE NUMBER
            </Text>
            <Row mb={theme.space.xs}>
              <PhoneNumberWithStatus
                phoneNumber={worker.phoneNumber}
                phoneNumberStatus={worker.phoneNumberStatus}
              />
            </Row>
            <Text variant="caption" mb={theme.space.xxs}>
              ZIP CODE / REGION
            </Text>
            <Row
              alignCenter
              style={{ height: theme.space.sm }}
              mb={theme.space.xs}
            >
              <Text variant="h7" style={{ marginRight: theme.space.xxs }}>
                {worker.zipCode}
              </Text>
              <Badge title={worker.regionId} />
            </Row>
            <Text variant="caption" mb={theme.space.xxs}>
              REFERRAL CODE
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {worker.referralCode ?? '-'}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              REFERRED BY
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {worker.referredBy ?? '-'}
            </Text>
          </Col>

          <Col>
            <Row alignCenter mb={theme.space.xxs}>
              <Text variant="caption">
                Date of Birth
                {worker.backgroundCheck?.reportedDob && (
                  <InfoTooltip
                    title={`Worker's reported DoB: ${worker.backgroundCheck?.reportedDob}`}
                  />
                )}
              </Text>
            </Row>
            <Text variant="h7" mb={theme.space.xs}>
              {worker.backgroundCheck?.candidate?.dob || 'Not collected'}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              LIFETIME EARNINGS
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {getMoneyString(
                (!hotSettings?.newEarningsTotalsEnabled
                  ? (metrics?.netPayTotals?.usd ?? 0)
                  : (lifetimeEarnings ?? 0)) / 100,
              )}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              AVERAGE PAY PER SHIFT
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {getMoneyString(averagePayPerShift / 100)}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              INSTANT PAY ENABLED
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {worker.payment?.instantPayEnabled ? 'Yes' : 'No'}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              INSTANT PAY ELIGIBLE
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {workerEligibleForInstantPay ? 'Yes' : 'No'}
            </Text>
            {worker.payment?.instantPayEligibility?.reasons?.length ? (
              <>
                <Text variant="caption" mb={theme.space.xxs}>
                  INSTANT PAY FLAGS
                </Text>
                {worker.payment?.instantPayEligibility?.reasons.map((flag) => (
                  <Text variant="h7" mb={theme.space.xs}>
                    {flag}
                  </Text>
                ))}
              </>
            ) : (
              <></>
            )}
          </Col>
          {hotSettings?.newEarningsTotalsEnabled && (
            <Col>
              <Text variant="caption" mb={theme.space.xxs}>
                STRIPE PAID OUT
              </Text>
              <Text variant="h7" mb={theme.space.xs}>
                {getMoneyString(stripePaidOut)}
              </Text>
              <Text variant="caption" mb={theme.space.xxs}>
                STRIPE BALANCE
              </Text>
              <Text variant="h7" mb={theme.space.xs}>
                {getMoneyString(stripeBalance)}
              </Text>
              <Text variant="caption" mb={theme.space.xxs}>
                STRIPE PENDING
              </Text>
              <Text variant="h7" mb={theme.space.xs}>
                {getMoneyString(stripePending)}
              </Text>
              <Text variant="caption" mb={theme.space.xxs}>
                TRABA PENDING
              </Text>
              <Text variant="h7" mb={theme.space.xs}>
                {getMoneyString(trabaPendingAmount)}
              </Text>
            </Col>
          )}

          <Col>
            <Text variant="caption" mb={theme.space.xxs}>
              WORKER TIER
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {workerTier ? `${workerTier.tier}` : '-'}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              LMCR
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {toPercentString(metrics?.lateCancellationRate || 0)}%
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              OTA
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {toPercentString(metrics?.onTimeRate || 0)}%
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              NO-SHOW RATE
            </Text>
            <Text variant="h7" mb={theme.space.xs} style={{ color: '#C20A47' }}>
              {toPercentString(metrics?.noShowRate || 0)}%
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              GENDER
            </Text>
            <Row mb={theme.space.xxs}>
              <Text variant="h7" pr={theme.space.xxs}>
                Reported Gender:
              </Text>
              <GenderBadge gender={worker.reportedGender} />
            </Row>
            <Row mb={theme.space.xs}>
              <Text variant="h7" pr={theme.space.xxs}>
                Ops Reported Gender:
              </Text>
              <GenderBadge gender={worker.opsReportedGender} />
            </Row>
            <Text variant="caption" pr={theme.space.xxs}>
              Last Active:
            </Text>
            <Text variant="h7" mb={theme.space.xxs}>
              {tz.getDateTime(worker.lastActiveAt)}
            </Text>
          </Col>

          <Col>
            <Text variant="caption" mb={theme.space.xxs}>
              LANGUAGES
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {workerLanguages && workerLanguages.length > 0
                ? getLanguageNames(
                    workerLanguages
                      .filter((wl) => wl.status !== WorkerProfileStatus.Revoked)
                      .map((wl) => wl.profileField),
                  )
                : '-'}
            </Text>
            <Row alignCenter mb={theme.space.xxs}>
              <Text variant="caption" mr={theme.space.xxs}>
                TRANSPORTATION
              </Text>
              <InfoTooltip title="How the worker usually gets to work as indicated on the onboarding survey, or whether or not they have indicated they have access to a vehicle." />
            </Row>
            <Text variant="h7" mb={theme.space.xs}>
              {getTransportationText(worker.workPreferences?.transportation) ||
                (worker.accessToVehicle ? 'Has access to vehicle' : 'None')}
            </Text>
            <Text variant="caption" mb={theme.space.xxs}>
              SHIFTS COMPLETED
            </Text>
            <Text variant="h7" mb={theme.space.xs}>
              {metrics?.completedShifts}
            </Text>

            <Text variant="caption" mr={theme.space.xxs}>
              TRAININGS COMPLETED
            </Text>
            {worker.isTrainingShiftComplete && (
              <Row alignCenter>
                <Text variant="h7">Training Shift</Text>
                <InfoTooltip title={trainingSummary} />
              </Row>
            )}
            {completedTrainings?.map((training) => (
              <Text variant="h7" key={training.trainingId}>
                {training.training.nameEn}
              </Text>
            ))}
          </Col>
          <Col>
            <Text variant="caption" mb={theme.space.xxs}>
              BACKGROUND CHECK STATUS
            </Text>
            <Row mb={theme.space.xxs}>
              <BackgroundCheckStatusBadge
                status={
                  worker.backgroundCheck?.status ||
                  BackgroundCheckStatus.PENDING
                }
                adjudication={
                  worker.backgroundCheck?.adjudication as AdjudicationStatus
                }
                assessment={
                  worker.backgroundCheck
                    ?.assessment as BackgroundCheckAssessment
                }
                onActionUpdate={handleBackgroundCheckAction}
                hasActiveAction={hasActiveBackgroundCheckAction}
                isLoading={
                  isRefetchingWorkerRequirementActions ||
                  isFetchingWorker ||
                  createOrUpdateWorkerRequirementAction.isPending
                }
              />
            </Row>
            <Text variant="caption" mb={theme.space.xxs}>
              PAYOUTS STATUS
            </Text>
            <Row mb={theme.space.xxs}>
              <StripeAccountPayoutsBadge accountStatus={worker} />
            </Row>
            <Text variant="caption" mr={theme.space.xxs}>
              W-9 STATUS
            </Text>
            <Row mb={theme.space.xxs}>
              <W9FormStatusBadge
                hasActiveAction={hasActiveW9FormAction}
                onUpdateAction={handleW9FormActionUpdate}
                w9FormStatus={worker.w9Form?.status}
                isLoading={
                  isRefetchingWorkerRequirementActions ||
                  isFetchingWorker ||
                  isLoadingW9FormUpdate ||
                  createOrUpdateWorkerRequirementAction.isPending
                }
              />
            </Row>
            <Text variant="caption" mb={theme.space.xxs}>
              PHONE NUMBER STATUS
            </Text>
            <Row mb={theme.space.xxs}>
              <PhoneNumberStatusBadge
                phoneNumberStatus={worker.phoneNumberStatus}
                hasActiveAction={hasActivePhoneNumberAction}
                onStatusUpdate={handlePhoneNumberStatusUpdate}
                isLoading={
                  isRefetchingWorkerRequirementActions ||
                  isFetchingWorker ||
                  createOrUpdateWorkerRequirementAction.isPending
                }
              />
            </Row>
            {isW2Worker && (
              <>
                <Text variant="caption" mb={theme.space.xxs}>
                  ZEAL ACCOUNT ID
                </Text>
                <Row mb={theme.space.xxs}>
                  <Text variant="h7">
                    {worker.zealEmployeeId ?? 'Not created yet'}
                  </Text>
                  {worker.zealEmployeeId && (
                    <SvgIcon
                      name="link"
                      onClick={() => {
                        window.open(
                          `${ZEAL_PORTAL_BASE_URL}/employees/${worker.zealEmployeeId}`,
                          '_blank',
                          'noopener,noreferrer',
                        )
                      }}
                    />
                  )}
                </Row>
              </>
            )}
          </Col>
        </Row>
        <TabsContainer>
          {!!workerId && (
            <WorkerNoteDrawer
              workerId={workerId}
              isOpen={notesOpen}
              setIsOpen={setNotesOpen}
            />
          )}
          <Tabs
            value={currentTab}
            onChange={(_, v) => {
              setCurrentTab(v)
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Shifts" />
            <Tab label="Paid Backups" />
            <Tab label="Waitlists" />
            <Tab label="Shift Invites" />
            <Tab label="Earnings" />
            <Tab label="Payment History" />
            <Tab label="Biz Messages" />
            <Tab label="Drug Screening" />
            <Tab label="Worker Messages" />
            <Tab label="Incentives" />
            <Tab label="Qualifications" />
            <Tab label="Attributes & Certifications" />
            <Tab label="Connections" />
            <Tab label="Eligibility Connections" />
            <Tab label="Hired" />
            <Tab label="Resumes" />
            <Tab label="Warden Exemptions" />
          </Tabs>
        </TabsContainer>
        <TabPanel value={currentTab} index={TabIndex.SHIFTS}>
          {workerId && (
            <WorkerShiftsTab
              workerId={workerId}
              workerShifts={workerShifts}
              isLoadingWorkerShifts={isLoadingWorkerShifts}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.PAID_BACKUPS}>
          {paidBackups && (
            <PaidBackupsTab
              paidBackups={paidBackups}
              isLoadingPaidBackups={isLoadingPaidBackups}
              refetchPaidBackups={refetchPaidBackups}
            />
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.WAITLISTS}>
          <WaitlistsTab
            workerId={worker.uid}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.INVITED_SHIFTS}>
          <InvitedShiftsTab
            workerId={worker.uid}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.EARNINGS}>
          <WorkerEarningsTab
            workerId={worker.uid}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.PAYMENTS_HISTORY}>
          <PaymentHistoryTab
            workerId={worker.uid}
            dateRange={dateRange}
            setDateRange={setDateRange}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.BIZ_MESSAGES}>
          <MessagesTab worker={worker} lastChatRef={lastChatRef} />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.DRUG_SCREENING}>
          <Row flexCol mt={theme.space.med}>
            <Text mb={theme.space.xs} variant="h5">
              Results
            </Text>
            {workerId && <WorkerDrugScreeningResults workerId={workerId} />}
          </Row>
          <Row flexCol>
            <Text
              mb={theme.space.xs}
              variant="h5"
              style={{ marginTop: theme.space.xs }}
            >
              Orders
            </Text>
            {workerId && (
              <WorkerDrugScreeningOrders
                workerId={workerId}
                workerName={`${worker.firstName} ${worker.lastName}`}
              />
            )}
          </Row>
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.WORKER_MESSAGES}>
          <WorkerMessagesTab workerId={worker.uid} />
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.INCENTIVES}>
          <Row flexCol>
            <Row justifyBetween mb={theme.space.xs}>
              <Text variant="h5">Worker Incentives</Text>
              {/* TODO(joey): add this back once grant incentive page is built */}
              {/* <Button slim variant="outlined" style={{}}>
              Grant Incentive
            </Button> */}
            </Row>
            {workerId && <WorkerIncentivesTab workerId={workerId} />}
          </Row>
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.QUALIFICATIONS}>
          {hotSettings?.allowStoringRequiredAttributeLevel ? (
            <QualificationsTab workerId={worker.uid} />
          ) : (
            <Text variant="body1">Coming Soon!</Text>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.ATTRIBUTES_CERTIFICATIONS}>
          <Row flexCol mt={theme.space.med}>
            <Text mb={theme.space.xs} variant="h5">
              Certifications
            </Text>
            {workerId && <WorkerCertifications workerId={workerId} />}
          </Row>
          <Row flexCol>
            <Text mb={theme.space.xs} variant="h5">
              Attributes
            </Text>
            <WorkerAttributes workerShifts={workerShifts} />
          </Row>
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.CONNECTIONS}>
          <Row flexCol mt={32}>
            <Text mb={theme.space.xs} variant="h5">
              Connections
            </Text>
            {workerId && <WorkerConnections workerId={workerId} />}
          </Row>
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.ELIGIBILITY_CONNECTIONS}>
          <Row flexCol mt={theme.space.med}>
            <Text mb={theme.space.xs} variant="h5">
              Eligibility Connections
            </Text>
            {workerId && <WorkerEligibilityConnections workerId={workerId} />}
          </Row>
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.HIRED}>
          <Row flexCol mt={theme.space.xxs}>
            {workerId && (
              <WorkerJobPlacements
                workerId={workerId}
                status={WorkerJobPlacementStatus.ACTIVE_WORKER}
              />
            )}
          </Row>
          <Row flexCol mt={theme.space.xxs}>
            {workerId && (
              <WorkerJobPlacements
                workerId={workerId}
                status={WorkerJobPlacementStatus.TERMINATED_WORKER}
              />
            )}
          </Row>
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.RESUMES}>
          {resumesAreLoading || !resumes ? (
            <CircularProgress size="medium" />
          ) : (
            <Row flexCol mt={32}>
              <Text mb={theme.space.xs} variant="h5">
                Resumes
              </Text>
              {resumes.resumes.map((resume, i) => {
                return (
                  <ListItem divider>
                    <Row
                      style={{
                        flex: 1,
                        justifyContent: 'space-between',
                      }}
                    >
                      <ListItemButton
                        onClick={() => {
                          openInNewTab(resume.downloadUrl)
                        }}
                        sx={{ justifyContent: 'space-between' }}
                      >
                        <Text variant="link">{`Resume_${i + 1}.pdf`}</Text>
                      </ListItemButton>
                      <IconButton
                        iconName="trash"
                        onClick={() => {
                          setResumeIdToDelete(resume.resumeId)
                          setShowDeleteResumeModal(true)
                        }}
                      />
                    </Row>
                  </ListItem>
                )
              })}
            </Row>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={TabIndex.WARDEN_EXEMPTIONS}>
          <WardenExemptionsTab workerId={workerId ?? ''} />
        </TabPanel>
      </MainLayout>
      <ConfirmationDialog
        open={showDeleteResumeModal}
        onClose={() => {
          setResumeIdToDelete(undefined)
          setShowDeleteResumeModal(false)
        }}
        title={`Delete resume?`}
        onConfirm={async () => {
          if (!resumeIdToDelete) {
            return
          }
          setDeleteLoading(true)
          await deleteResume(resumeIdToDelete)
          setDeleteLoading(false)
          setResumeIdToDelete(undefined)
          setShowDeleteResumeModal(false)
        }}
        content="Are you sure you want to delete the resume?"
        confirmLoading={deleteLoading}
      />
    </>
  )
}
